.fall {
  animation: fall 1s ease-in forwards;
}

@keyframes fall {
  0% {
    top: 0%;
  }

  80% {
    height: 100%;
    top: calc(100vh - 100% - 30%);
  }

  90% {
    height: 90%;
    top: calc(100vh - 90% - 30%);
  }

  100% {
    height: 100%;
    top: calc(100vh - 100% - 30%);
  }
}

.rise {
  animation: rise 1s ease-in forwards;
}

@keyframes rise {
  0% {
    top: 0%;
  }

  100% {
    top: -2000%;
    opacity: 1;
  }
}

.hit {
  animation: hit 1s ease-in forwards;
}

@keyframes hit {
  0% {
    top: 0%;
  }

  40% {
    top: calc(0% - 150px);
    height: 100%;
  }

  50% {
    top: calc(0% - 150px);
    height: 90%;
  }

  60% {
    top: calc(0% - 150px);
    height: 100%;
  }

  100% {
    top: 0%;
    height: 100%;
  }
}

.newCard {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.winner {
  animation: winner 2s ease-in-out forwards;
}

@keyframes winner {
  0% {
    top: 0%;
  }

  100% {
    top: -100%;
  }
}

.gradient {
  background-image: linear-gradient(15deg,
      hsl(16deg 83% 53%) 0%,
      hsl(16deg 83% 53%) 55%,
      hsl(0deg 0% 7%) 55%,
      hsl(0deg 0% 7%) 100%);
}